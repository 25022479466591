const STATIC_DATA = {
    lbArr: [
        {
            title: '大数据',
            desc: '海量不动产结构化数据、海量不动产非结构化数据、洞察发现、建模预测、自动监测',
            icon: require('../../assets/image/home/lb0.png')
        },
        {
            title: '人工智能',
            desc: '数据融合、自然语言处理NLP机器学习',
            icon: require('../../assets/image/home/lb1.png')
        },
        {
            title: '行业经验',
            desc: '20年不动产咨询经验、不动产业务场景解决方案全覆盖、不动产百强企业全覆盖',
            icon: require('../../assets/image/home/lb2.png')
        }
    ]
}

export default STATIC_DATA

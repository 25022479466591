













































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Popup } from 'vant'
import { Swipe, SwipeItem } from 'vant'
import API from '@/api/index.js'
import Tools from '@/utils/index.js'
import STATIC_DATA from './config.data.js'

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Popup)
@Component({
    components: {}
})
export default class Home extends Vue {
    show = false
    lbArr = STATIC_DATA.lbArr
    logoArr = new Array(7)

    get isLogin() {
        return !!this.$store.state.login.accessToken
    }

    mounted() {
        // 页面效果
        const scrollCon: any = document.getElementById('scroll_con')
        scrollCon.addEventListener('scroll', this.doScrollFun)
        this.doScrollFun()
        // 禁止返回
        this.noBack()
        // 判断本地token状态，用户直接进入首页时用localstorage token登录
        this.checkToken()
    }

    async checkToken() {
        // 如果store内有userid说明已登录，如果无local accesstoken，均需返回
        if (
            this.$store.state.login.userInfo.userId !== '' ||
            localStorage.getItem('yt_h5_accesstoken') === '' ||
            localStorage.getItem('yt_h5_accesstoken') === null
        ) {
            return
        }
        const res = await API.checkTokenStatus({ accessToken: localStorage.getItem('yt_h5_accesstoken') })
        if (res && res.data && res.data.errorCode === 200) {
            this.$store.commit('login/updateUserInfo', res.data.data)
            this.$store.commit('login/updateAccessToken', res.data.data.accessToken)
        } else {
            localStorage.setItem('yt_h5_accesstoken', '')
        }
    }

    openLinkUrl(url: any) {
        window.location.href = `${url}?token=${this.$store.state.login.accessToken}`
    }

    showPop() {
        this.show = true
    }

    closePop() {
        this.show = false
    }

    logout() {
        // 去除微信登录授权按钮
        localStorage.setItem('yt_h5_accesstoken', '')
        this.$store.commit('login/updateIsWxBrower', false)
        this.$store.commit('login/updateUserInfo', {})
        this.$store.commit('login/updateAccessToken', '')

        this.$nextTick(() => {
            this.$router.push({
                name: 'phoneLogin'
            })
        })
    }

    login() {
        // 去除微信登录授权按钮
        this.$store.commit('login/updateIsWxBrower', false)

        this.$nextTick(() => {
            this.$router.push({
                name: 'phoneLogin'
            })
        })
    }

    beforeDestroy() {
        const scrollCon: any = document.getElementById('scroll_con')
        if (scrollCon) {
            scrollCon.removeEventListener('scroll', this.doScrollFun)
        }
    }

    doScrollFun() {
        const scrollCon: any = document.querySelector('#scroll_con')
        const scrollTop = scrollCon.scrollTop
        const screenHeight = document.body.clientHeight
        const paddingTop = 80

        const scrollAnimate = document.querySelectorAll('.scrollAnimate')
        if (!scrollAnimate || scrollAnimate.length == 0) {
            return
        }
        scrollAnimate.forEach((item: any) => {
            const duration = item.getAttribute('duration') || 100
            const top = Tools.getElementTop(item)

            const animate = item.getAttribute('animate') || 'fadeIn'
            if (scrollTop + screenHeight >= top + paddingTop) {
                setTimeout(function () {
                    if (!Tools.hasClass(item, 'animated')) {
                        item.style.visibility = 'visible'
                        Tools.addClass(item, 'animated ' + animate)
                    }
                }, duration)
            }
        })
    }

    noBack() {
        history.pushState(null, 'null', document.URL)
        window.addEventListener('popstate', function () {
            history.pushState(null, 'null', document.URL)
        })
    }
}
